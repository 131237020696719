import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { endOfDay, startOfDay } from "date-fns";

import { RootState } from "../../store";

export interface StatsState {
  start: Date;
  stop: Date;
  withoutCalls: boolean;
}

const initialState: StatsState = {
  start: startOfDay(new Date()),
  stop: endOfDay(new Date()),
  withoutCalls: false,
};

export const statsSlice = createSlice({
  name: "stats",
  initialState,
  reducers: {
    setStart(state, { payload }: PayloadAction<Date>) {
      state.start = payload;
    },
    setStop(state, { payload }: PayloadAction<Date>) {
      state.stop = payload;
    },
    setWithoutCalls(state, { payload }: PayloadAction<boolean>) {
      state.withoutCalls = payload;
    },
  },
});

export const { setStart, setStop, setWithoutCalls } = statsSlice.actions;
export const selectStats = (state: RootState) => state.stats;
export default statsSlice.reducer;
