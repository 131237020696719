import React from "react";

import _ from "lodash";
import { Button } from "react-bootstrap";
import Icon from "react-icons-kit";
import { ic_cancel } from "react-icons-kit/md";

const CancelButton: typeof Button = React.memo((props) => (
  <Button variant="secondary" {..._.omit(props, ["children"])}>
    <Icon icon={ic_cancel} /> {props.children}
  </Button>
));

export default CancelButton;
