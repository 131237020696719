import { useAppSelector } from "../../hooks";
import Flash from "./Flash";
import { selectFlash } from "./flashSlice";

export default function FlashList() {
  const { messages } = useAppSelector(selectFlash);

  if (!messages) return null;

  return (
    <div className="ACD-flash-message-list">
      {messages.map((msg) => (
        <Flash key={msg.id} flash={msg} />
      ))}
    </div>
  );
}
