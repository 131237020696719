import {
  addDays,
  addHours,
  addMinutes,
  differenceInDays,
  format,
  formatDistance,
  formatDistanceToNow,
  isBefore,
  isSameDay,
  isSameSecond,
  isValid,
  parse,
  parseISO,
  parseJSON,
  setHours,
  subDays,
} from "date-fns";
import { de } from "date-fns/locale";
import isString from "lodash/isString";

type DateType = Date | string;

export const parsedDate = (date: DateType, local = true) => {
  if (isString(date)) {
    if (local) return parseDateLocal(date);
    // local ? parseISO(date) : parseJSON(date)
    return parseDateUtc(date);
  }

  return date;
};

const KNOWN_DATE_FORMATS = [
  "yyyy-MM-dd",
  "dd.MM.yyyy",
  "yyyy-MM-dd'T'HH:mm:ss.SSSSSS",
  "yyyy-MM-dd'T'HH:mm:ss.SSSX",
  "yyyy-MM-dd'T'HH:mm",
];

export const tryParsedDate = (date: string, formats = KNOWN_DATE_FORMATS) => {
  for (let i = 0; i < formats.length; ++i) {
    const rslt = parse(date, formats[i], new Date());

    if (isValid(rslt)) {
      return rslt;
    }
  }

  throw new Error(`Could not parse date: ${date}`);
};

export const parseDateLocal = (date: string) => {
  let rslt = parseISO(date);
  if (isValid(rslt)) {
    return rslt;
  }

  rslt = parse(date, "yyyy-MM-dd", new Date());
  if (isValid(rslt)) {
    return rslt;
  }

  throw new Error(`Unknown date format: ${date}`);
};

export const parseDateUtc = (date: string) => {
  let rslt = parseJSON(date);
  if (isValid(rslt)) {
    return rslt;
  }

  rslt = parse(date, "yyyy-MM-dd", new Date());
  if (isValid(rslt)) {
    return rslt;
  }

  throw new Error(`Unknown date format: ${date}`);
};

export const dateFormat = (date: DateType, formatStr: string | undefined = undefined, local = true) =>
  format(parsedDate(date, local), formatStr || "d.M.yyyy", { locale: de });
export const timeFormat = (date: DateType, formatStr: string | undefined = undefined) =>
  dateFormat(date, formatStr || "H:mm");
export const dateTimeFormat = (date: DateType, formatStr: string | undefined = undefined, local = true) =>
  dateFormat(date, formatStr || "d.M.yyyy H:mm", local);

export const detailedDateFormat = (date: DateType) => dateFormat(date, "EEEE, d. LLLL yyyy");
export const detailedDateTimeFormat = (date: DateType) => dateFormat(date, "EEEE, d. LLLL yyyy, H:mm 'Uhr'");

export const relativeTime = (date: DateType, fromDate = new Date(), local = true) =>
  formatDistance(parsedDate(date, local), fromDate, { locale: de, addSuffix: true });

export const indexDateWithTime = (date: DateType, fromDate = new Date(), local = false) => {
  date = parsedDate(date, local);

  if (isSameDay(date, fromDate)) {
    return format(date, "H:mm 'Uhr'");
  } else if (isSameDay(subDays(fromDate, 1), date)) {
    return format(date, "'gestern,' H:mm 'Uhr'");
  }

  return format(date, "d.M.yyyy',' H:mm 'Uhr'");
};

export const nearlySimultaneously = (date1: DateType, date2: DateType) =>
  isSameSecond(parsedDate(date1), parsedDate(date2));

export const durationToHours = (duration: number, withSeconds: boolean = false) => {
  const hours = Math.floor(duration / (60 * 60));
  duration %= 60 * 60;

  const minutes = Math.floor(duration / 60);
  duration %= 60;

  let str = `${hours}h${minutes}m`;

  if (withSeconds) {
    str += `${duration}s`;
  }

  return str;
};

export const durationToMinutes = (duration: number) => {
  const minutes = Math.floor(duration / 60);
  duration %= 60;

  if (minutes === 0) {
    return `${duration}s`;
  }

  return `${minutes}m${duration}s`;
};

export const dateToUtc = (date: Date) => addMinutes(date, date.getTimezoneOffset());

export const getFormat = (start: Date, stop: Date) => (differenceInDays(stop, start) < 1 ? "HH:mm" : "dd.MM.");
export const getGroup = (start: Date, stop: Date) => (differenceInDays(stop, start) < 1 ? "hour" : "day");

export const getDateRange = (start: Date, stop: Date, startHour = 7, stopHour = 18) => {
  const categories: Date[] = [];
  const group = getGroup(start, stop);
  const fun = group === "hour" ? addHours : addDays;

  if (group === "hour") {
    start = setHours(start, startHour);
    stop = setHours(stop, stopHour);
  }

  for (; isBefore(start, stop); ) {
    categories.push(start);
    start = fun(start, 1);
  }

  return categories;
};

export const timeOrDateTime = (date: DateType) => {
  date = parsedDate(date);

  if (isSameDay(date, new Date())) {
    return timeFormat(date);
  }
  return dateTimeFormat(date);
};

export const dateTimeSince = (dateString: string | undefined) => {
  if (!dateString) return "unbekannt";

  const date = parseISO(dateString);
  return `${formatDistanceToNow(date, { locale: de })}`;
};
