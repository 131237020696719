import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { endOfDay, startOfDay } from "date-fns";

import { RootState } from "../../store";
import { CallDirectionType, LineType, Nullable } from "../../types";

export interface ReachabilityState {
  start: Date;
  stop: Date;
  direction: Nullable<CallDirectionType>;
  line: Nullable<LineType>;
  ignoreRinging: Nullable<number>;
  timepoint: "INCOMING" | "START";
}

const initialState: ReachabilityState = {
  start: startOfDay(new Date()),
  stop: endOfDay(new Date()),
  direction: "INBOUND",
  line: null,
  ignoreRinging: 20,
  timepoint: "INCOMING",
};

export const callsSlice = createSlice({
  name: "calls",
  initialState,
  reducers: {
    setStart(state, { payload }: PayloadAction<Date>) {
      state.start = payload;
    },
    setStop(state, { payload }: PayloadAction<Date>) {
      state.stop = payload;
    },
    setDirection(state, { payload }: PayloadAction<Nullable<CallDirectionType>>) {
      state.direction = payload;
    },
    setLine(state, { payload }: PayloadAction<Nullable<LineType>>) {
      state.line = payload;
    },
    setIgnoreRinging(state, { payload }: PayloadAction<Nullable<number>>) {
      state.ignoreRinging = payload;
    },
    setTimepoint(state, { payload }: PayloadAction<"INCOMING" | "START">) {
      state.timepoint = payload;
    },
  },
});

export const { setStart, setStop, setDirection, setLine, setIgnoreRinging, setTimepoint } = callsSlice.actions;
export const selectReachability = (state: RootState) => state.reachability;
export default callsSlice.reducer;
