import { useEffect } from "react";

import { useAppDispatch } from ".";
import { setSubSubNav } from "../App/sessionSlice";
import { SubnavComponentType } from "../components/Layout/subnavComponents";

export default function useSubnav(nav: SubnavComponentType) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setSubSubNav(nav));

    return () => {
      dispatch(setSubSubNav(null));
    };
  }, [dispatch, nav]);
}
