import { gql } from "@apollo/client";

export const CURRENT_STATE_QUERY = gql`
  query currentStateQuery {
    currentEndpointState {
      type
      lastChange
      lastStatusChange
      endpoint {
        id
        number
      }

      eventStatus
      lastEvent {
        e164
        cn
      }

      status {
        id
        name
        stype
      }
    }
  }
`;

export const CURRENT_STATUS_QUERY = gql`
  query currentEndpointStatus($no: String!) {
    currentEndpointStatus(no: $no) {
      type
      endpoint {
        id
        number
      }

      eventStatus
      lastEvent {
        e164
        cn
      }

      status {
        id
        name
        stype
      }
    }
  }
`;

export const PBX_NUMBER_FRAGMENT = gql`
  fragment PbxNumberFragment on PbxCallEventNumber {
    type
    cn
    dn
    e164
    h323
  }
`;

export const PBX_INFO_FRAGMENT = gql`
  fragment PbxInfoFragment on PbxCallEventInfo {
    type
    vals
    vali
  }
`;

export const PBX_CALL_EVENT_FRAGMENT = gql`
  fragment PbxCallEventFragment on PbxCallEvent {
    user
    call
    reg
    active
    state
    msg
    conf

    no {
      ...PbxNumberFragment
    }

    info {
      ...PbxInfoFragment
    }
  }

  ${PBX_NUMBER_FRAGMENT}
  ${PBX_INFO_FRAGMENT}
`;

export const CURRENT_CALL_STATE_QUERY = gql`
  query currentCallState($lineIds: [ID]!) {
    currentCallState(lineIds: $lineIds) {
      lineId
      callGroupId
      initiator
      recipient
      activePeers
      connectedPbxIds
      callState
      callGroupState
      connected
      started
      waitingStopped
      stopped
      direction
      eventType

      event {
        ...PbxCallEventFragment
      }
    }
  }

  ${PBX_CALL_EVENT_FRAGMENT}
`;

export const WRITE_DEBUG_LOG_MUTATION = gql`
  mutation writeDebugLog($callId: String!) {
    writeDebugLog(callId: $callId)
  }
`;
