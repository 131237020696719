import { useState } from "react";

import { compareAsc } from "date-fns";

import { parsedDate } from "../../dates";
import useChannel from "../../hooks/useChannel";
import useChannelSubscription from "../../hooks/useChannelSubscription";
import { CallEventType } from "../../types";
import Call from "./Call";

type PropsType = {
  lineId: string;
  callGroups: CallEventType[];
};

export default function Line({ lineId, callGroups }: PropsType) {
  const [calls, setCalls] = useState(callGroups);
  const channel = useChannel(`line:${lineId}`);

  useChannelSubscription(channel, "call_event", (msg: CallEventType) => {
    setCalls((calls) => {
      const filtered = calls.filter((callGroup) => callGroup.callGroupId !== msg.callGroupId);

      if (msg.eventType !== "DISAPPEAR") {
        filtered.push(msg);
      }

      return filtered;
    });
  });

  return (
    <ul>
      {[...calls]
        .sort((a, b) => compareAsc(parsedDate(a.started), parsedDate(b.started)))
        .map((callGroup) => (
          <Call key={callGroup.callGroupId} call={callGroup} />
        ))}
    </ul>
  );
}
