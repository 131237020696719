import React from "react";

import _ from "lodash";
import { NavLink } from "react-router-dom";

const NavButton: typeof NavLink = React.memo((props) => {
  return (
    <NavLink className="btn btn-sm btn-secondary" {..._.omit(props, ["children"])}>
      {props.children}
    </NavLink>
  );
});

export default NavButton;
