import React from "react";

import _ from "lodash";
import { Button } from "react-bootstrap";
import Icon from "react-icons-kit";
import { ic_save } from "react-icons-kit/md";

const SaveButton: typeof Button = React.memo((props) => (
  <Button variant="primary" {..._.omit(props, ["children"])}>
    <Icon icon={ic_save} /> {props.children}
  </Button>
));

export default SaveButton;
