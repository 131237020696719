import "./styles/index.scss";

import React from "react";

import { ApolloProvider } from "@apollo/client";
import * as Sentry from "@sentry/react";

import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";

import client from "./apolloClient";
import App from "./App";
import { refreshUser, setUser } from "./App/sessionSlice";
import { getAuthorizationToken } from "./authenticationToken";
import "./socketHandlers";
import { store } from "./store";

const token = getAuthorizationToken();
if (token) {
  store.dispatch(refreshUser(client));
} else {
  store.dispatch(setUser(null));
}

const renderApp = (Component: () => JSX.Element) => {
  const container = document.getElementById("root");
  const root = createRoot(container!);

  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <ApolloProvider client={client}>
          <Router>
            <Component />
          </Router>
        </ApolloProvider>
      </Provider>
    </React.StrictMode>,
  );
};

Sentry.init({
  dsn: "https://35993e4ff376cfbf7b84f63cc58a63a5@o254619.ingest.us.sentry.io/4507837693427712",
  integrations: [Sentry.browserTracingIntegration()],
  enabled: process.env.NODE_ENV === "production",
  replaysSessionSampleRate: 0.0,
  replaysOnErrorSampleRate: 0.0,
  autoSessionTracking: false,
  release: process.env.REACT_APP_VERSION,
  environment: process.env.NODE_ENV,
});

renderApp(App);
