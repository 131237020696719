import { gql } from "@apollo/client";

export const SIGN_IN_MUTATION = gql`
  mutation signIn($email: String!, $password: String!) {
    signIn(email: $email, password: $password) {
      token

      user {
        id
        firstname
        lastname
        role
        email

        endpoint {
          id
          number

          status {
            id
            stype
            name
          }
        }
      }
    }
  }
`;

export const REFRESH_MUTATION = gql`
  mutation refresh($token: String!) {
    refresh(token: $token) {
      token

      user {
        id
        firstname
        lastname
        role
        email

        endpoint {
          id
          number

          status {
            id
            stype
            name
          }
        }
      }
    }
  }
`;
