import { may } from "../abilities";
import { SessionState } from "../App/sessionSlice";
import type {
  DashboardType,
  EndpointAuditLogEntry,
  EndpointType,
  LineType,
  Nullable,
  ProjectType,
  QueueType,
  StatusType,
  TokenType,
  UserType,
} from "../types";

export const adminUsersPath = () => `/admin/users`;
export const newAdminUserPath = () => `/admin/users/new`;
export const editAdminUserPath = (user: UserType) => `/admin/users/${user.id}`;

export const adminTokensPath = () => `/admin/tokens`;
export const newAdminTokenPath = () => `/admin/tokens/new`;
export const editAdminTokenPath = (user: TokenType) => `/admin/tokens/${user.id}`;

export const adminLinesPath = () => `/admin/lines`;
export const newAdminLinePath = () => `/admin/lines/new`;
export const editAdminLinePath = (line: LineType) => `/admin/lines/${line.id}`;

export const adminProjectsPath = () => `/admin/projects`;
export const newAdminProjectPath = () => `/admin/projects/new`;
export const editAdminProjectPath = (project: ProjectType) => `/admin/projects/${project.id}`;

export const adminStatesPath = () => `/admin/states`;
export const newAdminStatusPath = () => `/admin/states/new`;
export const editAdminStatusPath = (status: StatusType) => `/admin/states/${status.id}`;

export const adminEndpointsPath = () => `/admin/endpoints`;
export const newAdminEndpointPath = () => `/admin/endpoints/new`;
export const editAdminEndpointPath = (endpoint: EndpointType) => `/admin/endpoints/${endpoint.id}`;

export const adminQueuesPath = () => `/admin/queues`;
export const newAdminQueuePath = () => `/admin/queues/new`;
export const editAdminQueuePath = (queue: QueueType) => `/admin/queues/${queue.id}`;
export const editAdminQueuePrioritiesPath = (queue: QueueType) => `/admin/queues/${queue.id}/priorities`;

export const adminDashboardsPath = () => `/admin/dashboards`;
export const newAdminDashboardPath = () => `/admin/dashboards/new`;
export const editAdminDashboardPath = (dashboard: DashboardType) => `/admin/dashboards/${dashboard.id}`;

export const adminEndpointAuditLogPath = () => `/admin/endpoint-audit-log`;
export const showAdminEndpointAuditLogPath = (entry: EndpointAuditLogEntry) => `/admin/endpoint-audit-log/${entry.id}`;

export const firstAllowedAdminPath = (session: SessionState): Nullable<string> => {
  if (may(session, "admin/users")) {
    return adminUsersPath();
  }

  if (may(session, "admin/endpoints")) {
    return adminEndpointsPath();
  }

  if (may(session, "admin/lines")) {
    return adminLinesPath();
  }

  if (may(session, "admin/queues")) {
    return adminQueuesPath();
  }

  if (may(session, "admin/projects")) {
    return adminProjectsPath();
  }

  if (may(session, "admin/states")) {
    return adminStatesPath();
  }

  if (may(session, "admin/dashboards")) {
    return adminDashboardsPath();
  }

  if (may(session, "admin/tokens")) {
    return adminTokensPath();
  }

  return null;
};
