import ButtonGroup from "react-bootstrap/ButtonGroup";

import { may } from "../../abilities";
import { selectSession } from "../../App/sessionSlice";
import { NavButton } from "../../features/Buttons";
import { useAppSelector } from "../../hooks";
import {
  callsPath,
  dashboardsPath,
  firstAllowedAdminPath,
  reachabilityPath,
  rootPath,
  statsPath,
  supervisePath,
} from "../../pathes";

export default function MainSubnav() {
  const session = useAppSelector(selectSession);
  const adminPath = firstAllowedAdminPath(session);

  return (
    <ButtonGroup className="ACD-subnav">
      <NavButton to={rootPath()} end>
        Hauptseite
      </NavButton>
      {adminPath && <NavButton to={adminPath}>Administration</NavButton>}
      {may(session, "supervise") && <NavButton to={supervisePath()}>Supervisor</NavButton>}
      {may(session, "calls") && <NavButton to={callsPath()}>Calls</NavButton>}
      {may(session, "reachability") && <NavButton to={reachabilityPath()}>Erreichbarkeit</NavButton>}
      {may(session, "stats") && <NavButton to={statsPath()}>Statistiken</NavButton>}
      {may(session, "dashboards") && <NavButton to={dashboardsPath()}>Dashboards</NavButton>}
    </ButtonGroup>
  );
}
