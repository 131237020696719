import { Suspense } from "react";

import { Col, Container, Row } from "react-bootstrap";

import { selectSession } from "../../App/sessionSlice";
import FlashList from "../../features/Flash/FlashList";
import Loading from "../../features/Loading";
import { useAppSelector } from "../../hooks";
import Header from "./Header";
import MainSubnav from "./MainSubnav";
import Sidebar from "./Sidebar";
import subnavComponents from "./subnavComponents";

const Layout: React.ComponentType<React.PropsWithChildren<{}>> = ({ children }) => {
  const { subSubnav } = useAppSelector(selectSession);

  const SubSubnavComponent = (subSubnav ? subnavComponents[subSubnav] : null) || null;

  return (
    <>
      <Header />

      <Container as="main" role="main" fluid>
        <Row>
          <Sidebar />

          <Col lg={10}>
            <MainSubnav />
            {SubSubnavComponent && (
              <Suspense fallback={<Loading fullscreen backdrop />}>
                <div className="ACD-sub-sub-nav">
                  <SubSubnavComponent />
                </div>
              </Suspense>
            )}

            <FlashList />

            <Suspense fallback={<Loading stretch />}>{children}</Suspense>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default Layout;
