import React from "react";

import _ from "lodash";
import { Button } from "react-bootstrap";
import Icon from "react-icons-kit";
import { ic_add_circle } from "react-icons-kit/md";

const AddButton: typeof Button = React.memo((props) => (
  <Button variant="secondary" {..._.omit(props, ["children"])}>
    <Icon icon={ic_add_circle} /> {props.children}
  </Button>
));

export default AddButton;
