import React from "react";

import _ from "lodash";
import { Button } from "react-bootstrap";
import Icon from "react-icons-kit";
import { ic_content_copy } from "react-icons-kit/md";

const CopyButton: typeof Button = React.memo((props) => (
  <Button variant="secondary" size="sm" {..._.omit(props, ["children"])}>
    <Icon icon={ic_content_copy} /> {props.children}
  </Button>
));

export default CopyButton;
