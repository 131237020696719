import { gql } from "@apollo/client";

export const LINE_FRAGMENT = gql`
  fragment LineFragment on Line {
    id
    name
    active
    ringTimeout
    outboundPrefix
    numbers
    priority
    postprocessingTime
    rules
    greeting
    inDeletion

    projectId

    insertedAt
    updatedAt
  }
`;

export const LINES_QUERY = gql`
  query lines($search: String, $limit: Int, $offset: Int) {
    lines(search: $search, limit: $limit, offset: $offset) {
      ...LineFragment
    }
  }
  ${LINE_FRAGMENT}
`;

export const LINES_W_PROJECT_QUERY = gql`
  query linesWithProject($search: String, $limit: Int, $offset: Int) {
    lines(search: $search, limit: $limit, offset: $offset) {
      ...LineFragment
      project {
        id
        name
      }
    }
  }
  ${LINE_FRAGMENT}
`;

export const COUNT_LINES_QUERY = gql`
  query countLines($search: String) {
    countLines(search: $search)
  }
`;

export const LINE_QUERY = gql`
  query line($id: ID!) {
    line(id: $id) {
      ...LineFragment
    }
  }
  ${LINE_FRAGMENT}
`;

export const DELETE_LINE_MUTATION = gql`
  mutation deleteLine($id: ID!) {
    deleteLine(id: $id) {
      id
    }
  }
`;

export const LINE_MUTATION = gql`
  mutation mutateLine($id: ID, $line: LineInput!) {
    mutateLine(id: $id, line: $line) {
      ...LineFragment
    }
  }
  ${LINE_FRAGMENT}
`;
