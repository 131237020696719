import { useEffect, useState } from "react";

import { useMutation, useQuery } from "@apollo/client";

import { Form } from "react-bootstrap";

import { selectSession } from "../App/sessionSlice";
import { addErrorFlash } from "../features/Flash/flashSlice";
import { useAppDispatch, useAppSelector } from "../hooks";
import { CURRENT_STATUS_QUERY } from "../queries/pbx";
import { CHANGE_STATUS_MUTATION, STATES_QUERY } from "../queries/states";
import { CurrentEndpointStatusQueryType, EndpointStatusEvent, StatesDataType } from "../types";

type FormControlElement = HTMLInputElement | HTMLTextAreaElement;

export default function AcdStatus() {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(selectSession);
  const [currentStatus, setCurrentStatus] = useState<string | undefined>(undefined);
  const { data } = useQuery<StatesDataType>(STATES_QUERY);
  const [statusMutation] = useMutation(CHANGE_STATUS_MUTATION);

  const { data: currentStateData } = useQuery<CurrentEndpointStatusQueryType>(CURRENT_STATUS_QUERY, {
    variables: { no: user.endpoint?.number },
    skip: !user.endpoint?.number,
    onCompleted: (data) => setCurrentStatus(data.currentEndpointStatus.status?.id),
  });

  useEffect(() => {
    const updateState = (ev: EndpointStatusEvent) => {
      if (ev.detail.status) {
        setCurrentStatus(ev.detail.status.id);
      }
    };
    window.addEventListener("acd:myStatus", updateState);

    return () => {
      window.removeEventListener("acd:myStatus", updateState);
    };
  });

  async function updateStatus(ev: React.ChangeEvent<FormControlElement>) {
    try {
      await statusMutation({ variables: { id: ev.target.value, userId: user.id } });
    } catch (e) {
      console.log(e);
      dispatch(addErrorFlash());
    }
  }

  if (!data?.states.length || !currentStateData?.currentEndpointStatus) return null;

  return (
    <Form.Control as="select" value={currentStatus} onChange={updateStatus}>
      <option></option>
      {data.states.map((state) => (
        <option key={state.id} value={state.id}>
          {state.name}
        </option>
      ))}
    </Form.Control>
  );
}
