import React from "react";

import { Route, Routes } from "react-router-dom";

import Layout from "../components/Layout";
import LineStatus from "../features/LineStatus";

const AdminInterface = React.lazy(() => import("../components/Admin"));
const CallsInterface = React.lazy(() => import("../components/Calls"));
const DashboardsInterface = React.lazy(() => import("../components/Dashboards"));
const ReachabilityInterface = React.lazy(() => import("../components/Reachability"));
const StatsInterface = React.lazy(() => import("../components/Stats"));
const SuperviseInterface = React.lazy(() => import("../components/Supervise"));

export default function AuthedRoutes() {
  return (
    <Layout>
      <Routes>
        <Route path="/admin/*" element={<AdminInterface />} />
        <Route path="/calls/*" element={<CallsInterface />} />
        <Route path="/stats/*" element={<StatsInterface />} />
        <Route path="/reachability/*" element={<ReachabilityInterface />} />
        <Route path="/dashboards/*" element={<DashboardsInterface />} />
        <Route path="/supervise/*" element={<SuperviseInterface />} />
        <Route path="/" element={<LineStatus />} />
      </Routes>
    </Layout>
  );
}
