import { useMutation } from "@apollo/client";

import clsx from "clsx";
import { differenceInSeconds } from "date-fns";
import Icon from "react-icons-kit";
import { ic_arrow_right_alt, ic_save_outline } from "react-icons-kit/md";
import { useSelector } from "react-redux";

import { isAdmin } from "../../abilities/helpers";
import { selectSession } from "../../App/sessionSlice";
import { parsedDate } from "../../dates";
import { useAppDispatch } from "../../hooks";
import { WRITE_DEBUG_LOG_MUTATION } from "../../queries/pbx";
import { CallEventType, CallGroupStateType } from "../../types";
import { addSuccessFlash } from "../Flash/flashSlice";

type PropsType = { call: CallEventType };

const stateNames: Record<CallGroupStateType, string> = {
  UNHANDLED: "unbehandelt",
  DECLINED: "abgewiesen",
  IGNORED: "ignoriert",
  REDIRECTED: "weitergeleitet",
  DISPATCHED: "zugeteilt",
};

const isDisconnectedOrParked = (call: CallEventType) => {
  if (["DISCONNECT_RECEIVED", "DISCONNECT_SENT"].includes(call.callState[0]) && call.connectedPbxIds.length <= 0) {
    return true;
  }

  return call.callState[0] === "PARKED";
};

const classFromCallState = (call: CallEventType) => {
  if (isDisconnectedOrParked(call)) {
    return call.callState[0];
  } else if (call.connected) {
    return "CONNECT";
  }

  if (call.callState[0] === "CONNECT" && !call.connected) {
    return "ALERT";
  }

  return call.callState[0];
};

export default function Call({ call }: PropsType) {
  const dispatch = useAppDispatch();
  const diff = differenceInSeconds(new Date(), parsedDate(call.started, true));
  const minutes = Math.floor(diff / 60);
  const seconds = diff % 60;
  const { user } = useSelector(selectSession);
  const admin = isAdmin(user);

  const secStr = seconds < 10 ? `0${seconds}` : `${seconds}`;

  const [writeDebugLogMutation] = useMutation(WRITE_DEBUG_LOG_MUTATION);

  async function writeDebugLog() {
    await writeDebugLogMutation({ variables: { callId: call.callGroupId } });
    dispatch(addSuccessFlash(`Das Debug-Log wird geschrieben und ist unter /tmp/${call.callGroupId}* verfügbar.`));
  }

  return (
    <li className={clsx("ACD-call-state-entry", classFromCallState(call), { "has-debug": admin })}>
      <h3>
        {call.initiator} <Icon icon={ic_arrow_right_alt} /> {call.recipient || "???"}
      </h3>
      <span className="call-group-state">{stateNames[call.callGroupState]}</span>
      <span className="call-duration">{`${minutes}m${secStr}s`}</span>

      {admin && (
        <button onClick={writeDebugLog}>
          <Icon icon={ic_save_outline} />
        </button>
      )}
    </li>
  );
}
