import { combineReducers } from "@reduxjs/toolkit";

import sessionReducer from "./App/sessionSlice";
import callsReducer from "./components/Calls/callsSlice";
import reachabilityReducer from "./components/Reachability/reachabilitySlice";
import statsReducer from "./components/Stats/statsSlice";
import flashReducer from "./features/Flash/flashSlice";
import metaListReducer from "./features/MetaList/metaListSlice";

const rootReducer = combineReducers({
  session: sessionReducer,
  flash: flashReducer,
  metalist: metaListReducer,
  calls: callsReducer,
  reachability: reachabilityReducer,
  stats: statsReducer,
});

export default rootReducer;
