import * as Sentry from "@sentry/react";

import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import Loading from "../features/Loading";
import { useAppSelector } from "../hooks";
import { setContext } from "../sentry";
import AuthedRoutes from "./AuthedRoutes";
import LoginForm from "./LoginForm";
import { selectSession } from "./sessionSlice";

export default function App() {
  const { checked, user, loading } = useAppSelector(selectSession);
  const { pathname } = useLocation();

  if ((!checked || loading) && pathname !== "/login") return <Loading fullscreen backdrop />;
  if (!user && checked && !loading && pathname !== "/login") return <Navigate to="/login" />;

  return (
    <Sentry.ErrorBoundary beforeCapture={() => setContext()}>
      <Routes>
        <Route path="/login" element={<LoginForm />} />
        {user && <Route path="/*" element={<AuthedRoutes />} />}
      </Routes>
    </Sentry.ErrorBoundary>
  );
}
