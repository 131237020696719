import clsx from "clsx";
import { Spinner } from "react-bootstrap";

interface PropsInterface {
  backdrop?: boolean;
  fullscreen?: boolean;
  stretch?: boolean;
  translucent?: boolean;
}

export default function Loading({ backdrop, fullscreen, stretch, translucent }: PropsInterface) {
  const classList = clsx("ACD-loading", {
    backdrop,
    fullscreen,
    stretch,
    translucent,
  });

  return (
    <div className={classList}>
      <Spinner animation="border" color={backdrop ? "#fff" : "#000"}>
        <span className="visually-hidden">Lade…</span>
      </Spinner>
    </div>
  );
}
