import { SessionState } from "../App/sessionSlice";
import { AbilitiesPathType, Nullable, PermissionType } from "../types";
import AdminPermission from "./AdminPermissions";
import CallsPermissions from "./CallsPermissions";
import DashboardPermissions from "./DashboardPermissions";
import ReachabilityPermissions from "./ReachabilityPermissions";
import StatsPermissions from "./StatsPermissions";
import SupervisePermissions from "./SupervisePermissions";

const abilities: PermissionType<any> = {
  ...AdminPermission,
  ...CallsPermissions,
  ...StatsPermissions,
  ...DashboardPermissions,
  ...ReachabilityPermissions,
  ...SupervisePermissions,
};

export function may<T>(
  session: SessionState,
  path: AbilitiesPathType,
  action: string = "index",
  resource: Nullable<T> = null,
) {
  if (!action) {
    action = "index";
  }

  if (abilities[path]) {
    return (abilities[path]![action] || abilities[path]!.index || (() => false))(session, resource);
  }

  return false;
}
