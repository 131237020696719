import Dropdown from "react-bootstrap/Dropdown";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";

import { logoutUser, selectSession } from "../../App/sessionSlice";
import { useAppDispatch, useAppSelector } from "../../hooks";

export default function Header() {
  const { user } = useAppSelector(selectSession);
  const dispatch = useAppDispatch();

  return (
    <Navbar bg="light" expand="md" as="header" className="ACD-page-header">
      <div className="container-fluid">
        <Navbar.Brand as={Link} to="/">
          <img src="/logo.svg" alt="Termitel GmbH" />: ACD
        </Navbar.Brand>

        {user && (
          <Dropdown>
            <Dropdown.Toggle id="user-dropdown" className="user-button">
              {user.firstname} {user.lastname}
            </Dropdown.Toggle>

            <Dropdown.Menu id="user-dropdown">
              {/* <Dropdown.Item as={Link} to={`/${session.customer!.identifier}/change-password`}>
              Passwort ändern
            </Dropdown.Item> */}
              <Dropdown.Item as="button" onClick={() => dispatch(logoutUser())}>
                ausloggen
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
      </div>
    </Navbar>
  );
}
