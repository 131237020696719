import { Col } from "react-bootstrap";

import { selectSession } from "../../App/sessionSlice";
import AcdStatus from "../../features/AcdStatus";
import StatusPanel from "../../features/StatusPanel";
import { useAppSelector } from "../../hooks";

export default function Sidebar() {
  const { user } = useAppSelector(selectSession);

  return (
    <Col lg={2} className="ACD-sidebar">
      {user.endpoint && <AcdStatus />}
      <StatusPanel />
    </Col>
  );
}
