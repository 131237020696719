import { Suspense } from "react";

import { useMutation } from "@apollo/client";

import { Form, Formik, FormikHelpers } from "formik";
import { Form as BsForm, Button, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import Group from "../components/Forms/Group";
import Input from "../components/Forms/Input";
import FlashList from "../features/Flash/FlashList";
import Loading from "../features/Loading";
import { useAppDispatch, useTitle } from "../hooks";
import { SIGN_IN_MUTATION } from "../queries/session";
import { loginUser } from "./sessionSlice";

type ValuesType = { email: string; password: string };

const initialValues: ValuesType = {
  email: "",
  password: "",
};

const validationSchema = yup.object({
  email: yup.string().required("Bitte geben Sie die Login-Emailadresse an!"),
  password: yup.string().required("Bitte geben Sie das Passwort an!"),
});

export default function LoginForm() {
  const dispatch = useAppDispatch();
  const [signInMutation] = useMutation(SIGN_IN_MUTATION);
  const navigate = useNavigate();

  useTitle("Login");

  async function doLoginUser(values: ValuesType, { setSubmitting }: FormikHelpers<ValuesType>) {
    setSubmitting(true);
    try {
      await dispatch(loginUser(values.email, values.password, signInMutation));
      navigate("/");
    } catch (e) {
      setSubmitting(false);
    }
  }

  return (
    <Formik initialValues={initialValues} onSubmit={doLoginUser} validationSchema={validationSchema}>
      {({ isSubmitting }) => (
        <Suspense fallback={<Loading fullscreen backdrop />}>
          <div className="ACD-login-screen">
            <Form>
              <h1>Login Termitel-ACD</h1>

              <FlashList />

              <Group>
                <BsForm.Label className="visually-hidden" htmlFor="email">
                  Email
                </BsForm.Label>
                <Input name="email" id="email" type="email" />

                <BsForm.Label className="visually-hidden" htmlFor="password">
                  Passwort
                </BsForm.Label>
                <Input name="password" id="password" type="password" />
              </Group>

              <Button color="primary" type="submit" disabled={isSubmitting}>
                {isSubmitting && (
                  <Spinner animation="border">
                    <span className="visually-hidden">Lade…</span>
                  </Spinner>
                )}{" "}
                einloggen
              </Button>
            </Form>
          </div>
        </Suspense>
      )}
    </Formik>
  );
}
