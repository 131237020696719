import { useEffect, useRef, useState } from "react";

import { Channel } from "phoenix";
import { useSelector } from "react-redux";

import { selectSession } from "../App/sessionSlice";
import { phoenixSocket } from "../socketHandlers";
import { Nullable } from "../types";

export default function useChannel(id: string) {
  const channel = useRef<Nullable<Channel>>(null);
  const [joined, setJoined] = useState(false);
  const { socketConnected } = useSelector(selectSession);

  if (!channel.current && phoenixSocket && socketConnected) {
    channel.current = phoenixSocket.channel(id);
  }

  useEffect(() => {
    return () => {
      if (channel.current) {
        channel.current.leave().receive("ok", () => {
          setJoined(false);
          channel.current = null;
        });
      }
    };
  }, []);

  useEffect(() => {
    if (!channel.current || !socketConnected || joined) return;

    channel.current
      .join()
      .receive("ok", (msg) => setJoined(true))
      .receive("error", (msg) => {
        setJoined(false);
        console.log("error joining line channel", msg);
      });
  }, [socketConnected, joined]);

  if (joined) return channel.current;

  return null;
}
