import { Channel, Socket } from "phoenix";

import { setSocketConnected } from "./App/sessionSlice";
import { getAuthorizationToken } from "./authenticationToken";
import { store } from "./store";
import { EndpointStatus, EndpointType, Nilable, Nullable, SocketEvent } from "./types";

const socketPath =
  process.env.NODE_ENV === "development" ? `ws://${document.location.hostname}:4000/socket` : "/socket";
export let phoenixSocket: Nullable<Socket> = null;
export let endpointLobbyChannel: Nullable<Channel> = null;
export let endpointChannel: Nullable<Channel> = null;

function createPhoenixSocket(token: Nilable<string>) {
  const params: { token?: string } = {};

  if (token) {
    params.token = token;
  }

  const socket = new Socket(socketPath, { params });

  socket.onOpen(() => {
    store.dispatch(setSocketConnected(socket.isConnected()));
    const ev = new CustomEvent<Socket>("acd:socketConnect", { detail: socket });
    window.dispatchEvent(ev);
  });

  socket.onClose(() => {
    store.dispatch(setSocketConnected(socket.isConnected()));
    const ev = new CustomEvent<Socket>("acd:socketClose", { detail: socket });
    window.dispatchEvent(ev);
  });

  return socket;
}

const joinEndpointChannel = (endpoint: EndpointType, socket: Socket) => {
  endpointChannel = socket.channel("endpoint:" + endpoint.number);
  endpointChannel
    .join()
    .receive("ok", (resp) => console.log("Joined endpoint channel successfully", resp))
    .receive("error", (resp) => console.log("Unable to join", resp));

  endpointChannel.on("endpoint_status_changed", (msg: EndpointStatus) => {
    window.dispatchEvent(new CustomEvent("acd:myStatus", { detail: msg }));
  });
};

window.addEventListener("acd:socketConnect", (ev: SocketEvent) => {
  const socket = ev.detail;

  endpointLobbyChannel = socket.channel("endpoint:lobby");
  endpointLobbyChannel
    .join()
    .receive("ok", (resp) => console.log("Joined successfully", resp))
    .receive("error", (resp) => console.log("Unable to join", resp));

  endpointLobbyChannel.on("endpoint_status_changed", (msg: EndpointStatus) => {
    window.dispatchEvent(new CustomEvent("acd:endpointStatus", { detail: msg }));
  });

  const { user } = store.getState().session;

  if (user?.endpoint) {
    joinEndpointChannel(user.endpoint, socket);
  }
});

let token = getAuthorizationToken();

store.subscribe(() => {
  const newToken = getAuthorizationToken();

  if (newToken !== token) {
    token = newToken;
    phoenixSocket?.disconnect();
    phoenixSocket = createPhoenixSocket(token);

    endpointLobbyChannel = null;
    endpointChannel = null;

    const ev = new CustomEvent<Socket>("acd:socketOpen", { detail: phoenixSocket });
    window.dispatchEvent(ev);

    phoenixSocket.connect();
  }

  const { user } = store.getState().session;

  if (user && user.endpoint && phoenixSocket) {
    joinEndpointChannel(user.endpoint, phoenixSocket);
  }
});
