import { useEffect } from "react";

import { Channel } from "phoenix";

import { Nilable } from "../types";

export default function useChannelSubscription<T>(channel: Nilable<Channel>, event: string, handler: (msg: T) => void) {
  useEffect(() => {
    if (!channel) return;

    const subscriptionId = channel.on(event, handler);

    return () => channel.off(event, subscriptionId);
  }, [channel, event, handler]);
}
