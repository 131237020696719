import { PermissionType } from "../types";
import { isSupervisor } from "./helpers";

const SupervisePermissions: PermissionType<any> = {
  supervise: {
    index: ({ user }, _resource) => isSupervisor(user),
  },
};

export default SupervisePermissions;
