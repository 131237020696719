import { useDispatch, useSelector } from "react-redux";

import type { AppDispatch, RootState } from "../store";
import useDebounce from "./useDebounce";
import useDebounceCallback from "./useDebounceCallback";
import useList from "./useList";
import useSubnav from "./useSubnav";
import useTitle from "./useTitle";

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
export const useAppSelector = useSelector.withTypes<RootState>();

export { useDebounce, useDebounceCallback, useList, useSubnav, useTitle };
